<template>
    <div class="main">
        <div class="title">{{ title }}</div>
        <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-width="142px"
            label-position="right"
            size="small"
            style="max-width: 600px"
        >
            <el-form-item prop="name" :label="`${type === 'COPY' ? '作品名称' : '软著名称'}`">
                <el-input
                    style="width: 200px"
                    :placeholder="`${type === 'COPY' ? '请输入作品名称' : '请输入软件名称'}`"
                    v-model="formData.name"
                ></el-input>
            </el-form-item>
            <el-form-item prop="code" :label="`${type === 'COPY' ? '版权登记号' : '软著登记号'}`">
                <el-input
                    style="width: 200px"
                    :placeholder="`${type === 'COPY' ? '请输入版本登记号' : '请输入软著登记号'}`"
                    v-model="formData.code"
                ></el-input>
            </el-form-item>
            <el-form-item prop="registrationTime" label="登记日期">
                <el-date-picker
                    style="width: 200px"
                    v-model="formData.registrationTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="workType" :label="`${type === 'COPY' ? '作品类型' : '软著类型'}`">
                <el-select style="width: 200px" v-model="formData.workType" clearable filterable placeholder="请选择">
                    <el-option
                        v-for="item in workTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="block" prop="author" label="作者" v-if="type == 'COPY'">
                <el-select
                    style="width: 400px"
                    v-model="formData.author"
                    class="suffix"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入作者，按回车分割"
                    clearable
                    popper-class="hideSelect"
                >
                </el-select>
            </el-form-item>
            <el-form-item prop="owner" class="block" label="著作权人">
                <el-select
                    v-model="formData.owner"
                    class="suffix"
                    style="width: 400px"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入著作权人，按回车分割"
                    clearable
                    popper-class="hideSelect"
                >
                </el-select>
            </el-form-item>
            <el-form-item prop="img" class="block" :label="`${type === 'COPY' ? '作品附图' : '软著附图'}`">
                <multi-upload class="imgBox" v-model="formData.img" :limit="6">
                    {{
                        `${
                            type === 'COPY'
                                ? '请上传作品附图，格式JPG或PNG，大小不超过1M，最多可上传6张'
                                : '请上传软著附图，格式JPG或PNG，大小不超过1M，最多可上传6张'
                        }`
                    }}
                </multi-upload>
            </el-form-item>
            <el-form-item label="交易方式" prop="tradingMethod" style="width: 800px">
                <el-radio-group v-model="formData.tradingMethod">
                    <el-radio v-for="item in tradingMethodOption" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="expectedPrice" label="期望价（万元）">
                <el-input-number
                    v-model="formData.expectedPrice"
                    :disabled="formData.negotiateDirectly"
                    @change="Price"
                    :precision="1"
                    :controls="false"
                    placeholder="请输入"
                    style="margin-right: 10px; width: 200px"
                >
                </el-input-number>
                <el-checkbox @change="Directly" v-model="formData.negotiateDirectly">面议</el-checkbox>
            </el-form-item>
            <el-form-item prop="basePrice" label="底价（万元）">
                <el-input-number
                    style="width: 200px"
                    v-model="formData.basePrice"
                    :min="0"
                    :precision="1"
                    placeholder="请输入"
                    :controls="false"
                >
                </el-input-number>
            </el-form-item>
            <el-form-item prop="commissionType" label="佣金类型">
                <el-radio-group v-model="formData.commissionType">
                    <el-radio v-for="item in commissionTypeOptions" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                prop="commission"
                :label="formData.commissionType == 'COMMISSION' ? '佣金（万元）' : '佣金（%）'"
            >
                <el-input style="width: 200px" placeholder="请输入佣金" v-model="formData.commission"></el-input>
            </el-form-item>
            <el-form-item prop="contact" label="联系人">
                <el-input style="width: 200px" v-model="formData.contact"></el-input>
            </el-form-item>
            <el-form-item prop="phone" label="电话">
                <el-input style="width: 200px" v-model="formData.phone"></el-input>
            </el-form-item>
            <el-form-item prop="email" label="邮箱">
                <el-input style="width: 200px" v-model="formData.email"></el-input>
            </el-form-item>
            <el-form-item prop="address" label="所在地区">
                <el-input placeholder="请输入所在地区" v-model="formData.address"></el-input>
            </el-form-item>
        </el-form>
        <div class="btn">
            <el-button @click="onSave" :loading="saving" type="primary">确定</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button type="info" @click="$router.go(-1)" :disabled="saving">返回</el-button>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { phonePattern, tradingMethodOption } from '../../../utils/variables';
export default {
    name: 'CopyrightEdit',
    data() {
        return {
            saving: false,
            formData: {},
            workTypeOptions: [],
            tradingMethodOption,
            needChange: true,
            commissionTypeOptions: [
                { label: '佣金', value: 'COMMISSION' },
                { label: '百分比', value: 'PERCENTAGE' }
            ]
        };
    },
    computed: {
        ...mapState(['userInfo']),
        title() {
            const list = ['发布供给信息-版权111', '发布供给信息-软著'];
            return list[this.type == 'COPY' ? 0 : 1];
        },
        type() {
            return this.$route.query.type;
        },
        rules() {
            return {
                name: [
                    { required: true, message: `请输入${this.type === 'COPY' ? '作品' : '软著'}名称`, trigger: 'blur' }
                ],
                code: [
                    {
                        required: true,
                        message: `请输入${this.type === 'COPY' ? '版本' : '软著'}登记号`,
                        trigger: 'blur'
                    }
                ],
                // workType: [
                //     { required: true, message: `请选择${this.type === 'COPY' ? '作品' : '软著'}类型`, trigger: 'blur' }
                // ],
                // author: [{ required: true, message: '请输入作者，按回车分割', trigger: 'blur' }],
                // owner: [{ required: true, message: '请输入著作权人', trigger: 'blur' }],
                // img: [
                //     {
                //         required: true,
                //         message: `请上传${this.type === 'COPY' ? '作品' : '软著'}附图`,
                //         trigger: 'blur'
                //     }
                // ],
                // tradingMethod: [{ required: true, message: '请选择交易方式', trigger: 'blur' }],
                // expectedPrice: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             if (!this.formData.negotiateDirectly) {
                //                 if (!this.formData.expectedPrice) {
                //                     callback(new Error('请选择期望价方式'));
                //                 } else {
                //                     callback();
                //                 }
                //             } else {
                //                 callback();
                //             }
                //         }
                //     }
                // ],
                // basePrice: [{ required: true, message: '请输入底价', trigger: 'blur' }],
                // contact: [{ required: true, message: '请输入联系人' }],
                // phone: { required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' },
                // commissionType: [{ required: true, message: '请选择佣金类型', trigger: 'blur' }],
                // email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                // commission: [{ required: true, message: '请输入佣金', trigger: 'blur' }],
                // address: [{ required: true, message: '请输入所在地区', trigger: 'blur' }]
            };
        }
    },
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('copyright/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        this.$http
            .post('/setting/byFlag', { flag: this.type == 'COPY' ? 3 : 4 })
            .then(res => {
                if (res.length > 0) {
                    this.workTypeOptions = res.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.formData = {
            contact: this.userInfo.nickname,
            phone: this.userInfo.phone,
            email: this.userInfo.email
        };
    },

    methods: {
        Price() {
            if (this.formData.expectedPrice) {
                this.formData.negotiateDirectly = false;
            }
        },
        Directly() {
            if (this.formData.negotiateDirectly == true) {
                this.$set(this.formData, 'expectedPrice', undefined);
            }
            this.$forceUpdate();
        },
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/CopyrightEdit',
                                    query: {
                                        ...this.$route.query
                                    }
                                });
                                this.formData = '';
                                this.formData = {
                                    contact: this.userInfo.nickname,
                                    negotiateDirectly: false,
                                    phone: this.userInfo.phone,
                                    email: this.userInfo.email
                                };
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData, userId: this.userInfo.id, type: this.type, status: 'PENDING' };
            console.log(data);
            // data.owner = data.owner.join(',');
            // if (this.type === 'COPY') {
            //     data.author = data.author.join(',');
            // }
            this.saving = true;
            this.$http
                .post('/copyright/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/copyright/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
.el-tag {
    margin-bottom: 11px;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.input-new-tag {
    width: 100%;
    vertical-align: bottom;
}
/deep/ .el-checkbox__inner {
    border-radius: 50%;
}
/deep/ .el-form-item__label {
    width: 185px;
    white-space: nowrap;
    color: #313233;
    text-overflow: ellipsis;
    line-height: 40px;
}
/deep/ .el-form-item--small .el-form-item__content {
    line-height: 39px;
}
/deep/ .el-form {
    margin-top: 30px;
}
/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
}
/deep/ .el-button {
    width: 100px;
    border-radius: 4px;
    margin-left: 25px;
}
.suffix {
    /deep/ .el-input__suffix {
        opacity: 0;
    }
}
.main {
    background-color: #fff;
    margin: 17px 20px 0;
    padding-bottom: 60px;
    .btn {
        text-align: right;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
    }
}
</style>
